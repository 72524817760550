import { MISNAP_SUCCESS_STATUS } from '..';
import { MANUAL_HINTS } from '../hints';
import { Coordinates } from './coordinates.model';
import { FrameCaptureResponseWarnings } from './frame-capture-response-warnings.model';
import { MibiData } from './mibi-data.model';

export class FrameCaptureResponse {
  code: string;
  docType: string;
  failedImage: string;
  fourCornerA: Coordinates;
  fourCornerB: Coordinates;
  fourCornerC: Coordinates;
  fourCornerD: Coordinates;
  imageData: string;
  mibiData: MibiData;
  mode: string;
  status: string;
  warnings: FrameCaptureResponseWarnings;

  constructor(init?: Partial<FrameCaptureResponse>) {
    Object.assign(this, init);
  }

  get isSuccess(): boolean {
    return this.warnings?.status === MISNAP_SUCCESS_STATUS;
  }

  get statusMessage(): string {
    if (!this.isSuccess) {
      const errorCode = this.warnings.key;
      return MANUAL_HINTS[errorCode];
    }
    return undefined;
  }

  get imageBytes(): string {
    const imageData = this.imageData ?? this.failedImage;
    return imageData?.replace('data:image/jpeg;base64,', '');
  }
}
