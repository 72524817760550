import html from './document-capture.html';
import './document-capture.scss';

import { environment } from '../../environments/environment';
import {
  FrameCaptureResult,
  MiSnapResultsService,
  MiSnapService,
  RoutingService,
} from '../../services';
import { CaptureType } from '../../shared/types';
import { getDocumentDisplayName } from '../../shared/utils';
import state from '../../state';
import { Page } from '../page';
import { defaultOptions } from '../page-options';

const MANUAL_SNAP_DELAY = environment.envName === 'root' ? 1000 : 15000;

export class DocumentCapture extends Page {
  public get name() {
    return 'Document Capture';
  }

  public get layoutOptions() {
    return {
      ...defaultOptions,
      hideHeader: true,
      hideFooter: true,
      hideStepTracker: true,
      isFullScreen: true,
    };
  }

  private captureType: CaptureType = undefined;
  miSnapService: MiSnapService = MiSnapService.getInstance();
  miSnapResults: MiSnapResultsService = MiSnapResultsService.getInstance();
  isCaptureTriggeredFromStopSDK = false;

  private $manualSnapButton: HTMLElement;
  private manualTimeout: NodeJS.Timeout;

  constructor() {
    super(html);
  }

  getTemplateOptions(params: Record<string, string>): Record<string, string> {
    const displayName = getDocumentDisplayName(state.documentType);
    switch (params.type) {
      case 'passport':
        this.captureType = CaptureType.Passport;
        return {
          documentName: displayName,
          title: 'Photo of your ' + displayName,
          documentFeature: 'standard',
        };
      case 'front':
        this.captureType = CaptureType.IdFront;
        return {
          documentName: displayName,
          title: 'Front of ' + displayName,
          documentFeature: 'standard',
        };
      case 'back':
        this.captureType = CaptureType.IdBack;
        return {
          documentName: displayName,
          title: 'Back of ' + displayName,
          documentFeature: 'barcode',
        };
      default:
        return {
          documentName: 'Document',
          title: 'Photo of your Document',
          documentFeature: 'standard',
        };
    }
  }

  afterLoad(): void {
    if (!!!this.captureType) {
      return;
    }

    this.miSnapService.onCameraDisplayStarted(() => {
      this.hideLoadingSpinner();
    });

    this.miSnapService.startAutoCapture(this.captureType, state.documentType);
    this.miSnapService.onCaptureResult((result: FrameCaptureResult) => {
      if (this.isCaptureTriggeredFromStopSDK) {
        this.isCaptureTriggeredFromStopSDK = false;
        return;
      }
      this.onCaptureResult(result);
    });

    this.$manualSnapButton = document.getElementById('manual-snap-button');
    this.$manualSnapButton.addEventListener('click', () => {
      this.miSnapService.forceCaptureFrame();
    });

    this.manualTimeout = setInterval(() => {
      this.revealManualSnapButton();
    }, MANUAL_SNAP_DELAY);
  }

  stopImageCapture() {
    this.miSnapService.removeListenersAndStopSDK();
    if (this.manualTimeout) {
      clearInterval(this.manualTimeout);
    }
  }

  onCaptureResult(result: FrameCaptureResult) {
    this.miSnapResults.saveCapturedImage(this.captureType, result);
    this.stopImageCapture();
    this.navigateToNextPage();
  }

  navigateToNextPage() {
    let verifyType = '';

    if (this.captureType === CaptureType.Passport) {
      verifyType = 'passport';
    } else if (this.captureType === CaptureType.IdFront) {
      verifyType = 'front';
    } else if (this.captureType === CaptureType.IdBack) {
      verifyType = 'back';
    }

    if (verifyType !== '') {
      RoutingService.goToPage(`capture/${verifyType}-verify`);
    }
  }

  hideLoadingSpinner() {
    const spinner = document.getElementById('document-capture-loading-spinner');
    spinner.style.display = 'none';
  }

  showLoadingSpinner() {
    const spinner = document.getElementById('document-capture-loading-spinner');
    spinner.style.display = 'block';
  }

  revealManualSnapButton() {
    this.$manualSnapButton.classList.add(
      'document-capture__manual-snap-button--visible'
    );
    if (this.manualTimeout) {
      clearInterval(this.manualTimeout);
    }
  }
}
