import * as LogRocket from 'logrocket';
import { DEVICE_CODE_STORAGE_KEY } from '../../shared/constants';

export class LogRocketService {

  start() {
    const deviceCode = sessionStorage.getItem(DEVICE_CODE_STORAGE_KEY);
    if (deviceCode) {
      logRocketService.init('asqz2e/nexsys-technologies');
      logRocketService.identify(deviceCode);
    }
  }

  private init(appId: string) {
    LogRocket.init(appId, {
      mergeIframes: true,
      network: {
        isEnabled: false,
      },
    });
  }

  private identify(id: string) {
    LogRocket.identify(id);
  }
}

const logRocketService = new LogRocketService();
export default logRocketService;
