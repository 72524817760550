import { CaptureType } from '../../shared/types';

// Default Hints provided by MiTek documentation- any of these can be changed
export const AUTOCAPTURE_HINTS: Record<string, string> = {
  MITEK_ERROR_GLARE: 'Reduce Glare',
  MITEK_ERROR_FOUR_CORNER: 'Document Not Found',
  MITEK_ERROR_TOO_DARK: 'Too Dark. Use good lighting',
  MITEK_ERROR_FOCUS: 'The image is blurry',
  MITEK_ERROR_MRZ_MISSING: 'Passport not detected',
  CV_NO_BARCODE_FOUND: 'No barcode detected',
  MITEK_ERROR_TOO_FAR: 'Document Too Far',
  MITEK_ERROR_TOO_CLOSE: 'Document Too Close',
  MITEK_ERROR_NOT_CENTERED: 'Document Not Centered',
  MITEK_ERROR_MIN_PADDING: 'Move the camera further away from your document',
  MITEK_ERROR_HORIZONTAL_FILL: 'Move the camera closer to your document',
  MITEK_ERROR_LOW_CONTRAST: 'Center document on a dark background',
  MITEK_ERROR_BUSY_BACKGROUND: 'Center document on a plain background',
  MITEK_ERROR_SKEW_ANGLE:
    'Document is skewed. Hold camera directly over your document',
  MITEK_ERROR_PERPENDICULAR_DOCUMENT: 'Change the orientation of your document',
};

export const DOCUMENT_SPECIFIC_HINT_OVERRIDES: Record<
  CaptureType,
  Record<string, string>
> = {
  [CaptureType.Passport]: {
    MITEK_ERROR_FOUR_CORNER: 'Center photo page on a dark background',
  },
  [CaptureType.IdFront]: {
    MITEK_ERROR_FOUR_CORNER: 'Center document on a dark background',
  },
  [CaptureType.IdBack]: { MITEK_ERROR_FOUR_CORNER: 'Scanning for barcode' },
};

export const HINT_ID_NAME_PLACEHOLDER = '{{id-display-name}}';

export const INITIAL_HINTS: Record<CaptureType, string> = {
  [CaptureType.IdFront]: `Place your ${HINT_ID_NAME_PLACEHOLDER} on a dark background and fit it within the four corner guides on screen.`,
  [CaptureType.IdBack]: `Place your ${HINT_ID_NAME_PLACEHOLDER} on a dark background and and align the guide over your barcode.`,
  [CaptureType.Passport]:
    'Place your Passport on a dark background and fit it within the four corner guides on screen.',
};

// Verbiage displayed to users after they manually captured a photo of their document.
export const MANUAL_HINTS: Record<string, string> = {
  MITEK_ERROR_FOUR_CORNER: 'No ID detected',
  MITEK_ERROR_GLARE: 'Photo is blurry or has glare',
  MITEK_ERROR_TOO_DARK: 'Photo is too dark',
  MITEK_ERROR_FOCUS: 'Photo is blurry or has glare',
  MITEK_ERROR_MRZ_MISSING: 'No barcode detected',
  CV_NO_BARCODE_FOUND: 'No barcode detected',
  MITEK_ERROR_TOO_FAR: 'ID too far',
  MITEK_ERROR_TOO_CLOSE: 'ID too close',
  MITEK_ERROR_NOT_CENTERED: 'ID not centered',
  MITEK_ERROR_MIN_PADDING: 'ID too close',
  MITEK_ERROR_HORIZONTAL_FILL: 'ID too far',
  MITEK_ERROR_LOW_CONTRAST: 'Background too light or busy',
  MITEK_ERROR_BUSY_BACKGROUND: 'Background too light or busy',
  MITEK_ERROR_SKEW_ANGLE: 'Take photo from directly above ID',
  MITEK_ERROR_PERPENDICULAR_DOCUMENT: 'Rotate ID 90 degres ',
};
