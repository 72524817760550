import {
  ApplicationInsights,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import { AxiosError } from 'axios';

import { environment } from '../environments/environment';

export class ApplicationInsightsService {
  appInsights: ApplicationInsights;

  assemblyName = 'ClearSign.Services.IDVerification.UI';
  sessionId: string | null = null;

  defaultProperties = {
    AssemblyName: this.assemblyName,
    Environment: environment.envName,
    SessionId: this.sessionId,
  };
  constructor() {
    this.appInsights = new ApplicationInsights({
      config: environment.appInsights,
    });
    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer(this.telemeteryInitiaizer);
  }

  setSessionId(sessionId: string) {
    this.defaultProperties.SessionId = sessionId;
    this.sessionId = sessionId;
  }

  telemeteryInitiaizer = (envelope: ITelemetryItem) => {
    envelope.data.AssemblyName = this.assemblyName;
    envelope.data.Environment = environment.envName;
    envelope.data.SessionId = this.sessionId;
  };

  logPageView(name?: string, url?: string) {
    this.appInsights.trackPageView({
      name: name,
      uri: url,
      properties: { ...this.defaultProperties },
    });
  }

  logEvent(name: string, properties?: Record<string, string>) {
    this.appInsights.trackEvent(
      { name: name },
      { ...properties, ...this.defaultProperties }
    );
  }

  logMetric(
    name: string,
    average: number,
    properties?: Record<string, string>
  ) {
    this.appInsights.trackMetric(
      { name: name, average: average },
      { ...properties, ...this.defaultProperties }
    );
  }

  logTrace(message: string, properties?: Record<string, string>) {
    this.appInsights.trackTrace(
      { message: message },
      { ...properties, ...this.defaultProperties }
    );
  }

  logKnownError(
    exception: Error,
    message: string,
    properties: Record<string, string> = {}
  ) {
    this.appInsights.trackException(
      {
        exception,
        properties: {
          message,
          ...properties,
        },
      },
      this.defaultProperties
    );
  }

  handleGenericNetworkError() {
    return (err: AxiosError) => {
      this.logKnownError(err, 'Network error', { url: err.config.url });
    };
  }
}

const service = new ApplicationInsightsService();
export default service;
