export { CaptureFlowPage } from './capture-flow/capture-flow';
export { CongratsPage } from './congrats/congrats';
export { CompletedPage } from './completed/completed';
export { DocumentCapture } from './document-capture/document-capture';
export { LandingPage } from './landing-page/landing-page';
export { Page } from './page';
export { SubmissionFailedPage } from './submission-failed/submission-failed';

export { GenericErrorPage, InvalidLinkPage, NotFoundPage } from './error-pages';
export { PageOptions, defaultOptions } from './page-options';
