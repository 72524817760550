export const MISNAP_CAPTURE_OPTIONS = {
  qualityPercent: 90, // value between 50 to 100 recommended
  hintFrequencyMS: 1200, // how long the hint message displays (in MS) / note: 2400 recommended for screen readers
  hintAriaLive: 2, //screen reader setting for hint updates value (0=off / 1=polite / 2=assertive)
  guidePaddingLevel: 1, // size of the overlay guide (1=large, 2=medium, 3=small) / note: 1 is recommended
  hintMessageSize: 1, // set the size of the hint message (1=small / 2=medium / 3=large)
  disableMirroring: false, // if true, disables mirroring of live-preview for Selfie
  disablePerpendicularCapture: true, // if true, requires the orientation of the document to match the orientation of the device during capture
  disableSmileDetection: false, // disable smile detection
  faceDetectionLevel: 1, // determines how strict face detection is (1=lenient, 2=moderate, 3=strict)
  videoContainerId: 'misnap-video', // (optionally) embed the video in a custom HTML container
  videoRecordingEnabled: false, // (optionally) record the video session returned as a dataURL
  audioRecordingEnabled: false, // (optionally) also record audio only applies if videoRecordingEnabled = true)
  videoQuality: 70, // (optionally) value between 30 to 100 recommended (normalized bitrate of video / only applies if videoRecordingEnabled = true)
  videoRecordingMessage: 'Recording', // (optionally) override the default "Recording" message / Note: set to "" to disable icon and message
};