import hasher = require('hasher');

import { DocumentType, Routes } from '../../shared/types';
import state from '../../state';

export class SessionProgressionGuard {
  /**
   * Check if the the user can navigate to the requested step.
   * @param requestedRoute the step in the workflow that the user is trying to access.
   * @returns true if the step the user is trying to reach is invalid, otherwise false.
   */
  public isInvalidState(requestedRoute: string): boolean {
    const cannotGoBack =
      state.currentStep === Routes.SubmissionFailed ||
      state.currentStep === Routes.Congrats ||
      state.currentStep === Routes.Completed;

    return state.currentStep !== requestedRoute
        && (cannotGoBack ||
          (!state.visitedSteps.includes(requestedRoute) && state.visitedSteps.length > 0)
        );
  }

  /**
   * Reset the current and visited steps to the passed in value.
   * @param path the step to reset the state to.
   */
  public redirectResetState(path: string): void {
    const resetIndex = state.visitedSteps.indexOf(path);
  
    state.resetVisitedSteps(resetIndex >= 0 ? resetIndex : 0);
    state.setCurrentStep(path);
  }

  /**
   * Redirect user to the appropriate step in the workflow.
   * If the user is trying to go to an unvisited step, they will remain on the current page they are on.
   * If they are on a photo page, they will be redirected to the appropriate capture page to avoid camera load issues.
   */
  public redirect(): void {
    const redirectStep = this.determineRedirectRoute();
			
    // User pressed back button on the submission failed page 
    if (redirectStep.includes('capture/')) {
      this.redirectResetState(redirectStep);
      state.setVisitedSteps(redirectStep);
    }

    hasher.replaceHash(redirectStep);
  }

  /**
   * Get the appropriate redirect route.
   * @returns a string containing the appropriate route to redirect to.
   */
  private determineRedirectRoute(): string {
    const hashRoute = state.currentStep.split('/');

    if (hashRoute.includes('photo')) {
      return `capture/${hashRoute[1]}`;
    } else if (state.currentStep === Routes.SubmissionFailed) {
      return state.documentType === DocumentType.Passport ? Routes.CapturePassport : Routes.CaptureLicenseId;
    }

    return state.currentStep;
  }
}

const sessionProgressionGuard = new SessionProgressionGuard();
export default sessionProgressionGuard;
