export interface ClientTheme {
  primaryColorHexCode: string;
  primaryColorHoverBgHexCode: string;
  primaryColorHoverTextHexCode: string;
  secondaryColorHexCode: string;
  secondaryColorHoverBgHexCode: string;
  secondaryColorHoverTextHexCode: string;
  logoSmallUri: string;
  logoLargeUri: string;
  faviconUri: string;
  documentTitleText: string;
}

export const defaultClientTheme: ClientTheme = {
  logoLargeUri:
    'https://images-cdn.nexsysclearsign.com/clearsignlogo_large.png',
  logoSmallUri:
    'https://images-cdn.nexsysclearsign.com/clearsignlogo_small.png',
  primaryColorHexCode: '#2B0083',
  primaryColorHoverBgHexCode: '#2E1166',
  primaryColorHoverTextHexCode: '#FFFFFF',
  secondaryColorHexCode: '#603AA1',
  secondaryColorHoverBgHexCode: '#E7E2F2',
  secondaryColorHoverTextHexCode: '#603AA1',
  faviconUri: 'https://images-cdn.nexsysclearsign.com/favicon.ico',
  documentTitleText: 'Clear Sign | eSign Platform',
};
