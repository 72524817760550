import html from './generic-error.html';

import { Page } from '../../page';
import { defaultOptions } from '../../page-options';

export class GenericErrorPage extends Page {
  public get name() {
    return 'Generic Error';
  }

  public get layoutOptions() {
    return {
      ...defaultOptions,
      hideHeader: true,
    };
  }

  constructor() {
    super(html);
  }
}
