// eslint-disable-next-line @typescript-eslint/no-var-requires
const mitekScienceSDK = require('../../assets/mitek-science-sdk');

import { CaptureType, DocumentType } from '../../shared/types';
import { MISNAP_CAPTURE_OPTIONS } from './configuration/misnap-options.config';
import {
  AUTOCAPTURE_HINTS,
  DOCUMENT_SPECIFIC_HINT_OVERRIDES,
  HINT_ID_NAME_PLACEHOLDER,
  INITIAL_HINTS,
} from './hints';
import { FrameCaptureResult, FrameProcessingFeedback } from './models';
import { getDocumentDisplayName } from '../../shared/utils';
import appInsightsService from '../../services/application-insights.service';
import { RoutingService } from '../routing/routing.service';
import { SessionService } from '../session/session-service';

export class MiSnapService {
  private lastError: string;
  private license: string;

  async initialize(): Promise<void> {
    this.license = await SessionService.getSDKLicense();
  }

  onCameraDisplayStarted(callback: (result: unknown) => void) {
    mitekScienceSDK.on('CAMERA_DISPLAY_STARTED', callback);
  }

  onCaptureResult(callback: (result: FrameCaptureResult) => void) {
    mitekScienceSDK.on('FRAME_CAPTURE_RESULT', callback);
  }

  onManualCapture(callback: () => void) {
    mitekScienceSDK.on('IMAGE_CAPTURED', callback);
  }

  startAutoCapture(captureType: CaptureType, documentType: DocumentType): void {
    this.handleSdkErrors();
    this.startProcessingFeedback(captureType, documentType);

    // Display a starting hint to the user when capture begins
    this.onCameraDisplayStarted(() => {
      const message = INITIAL_HINTS[captureType];
      this.showHint(message, documentType);
    });

    mitekScienceSDK.cmd('CAPTURE_AND_PROCESS_FRAME', {
      mode: 'AUTO_CAPTURE',
      documentType: captureType.toString(),
      mitekSDKPath: './mitekSDK/',
      options: {
        ...MISNAP_CAPTURE_OPTIONS,
        license: this.license,
      },
    });
  }

  showHint(message: string, documentType: DocumentType) {
    message = message.replace(
      HINT_ID_NAME_PLACEHOLDER,
      getDocumentDisplayName(documentType)
    );
    mitekScienceSDK.cmd('SHOW_HINT', message);
  }

  removeListeners() {
    mitekScienceSDK.cmd('SDK_REMOVE_LISTENERS');
  }

  stopSDK() {
    mitekScienceSDK.cmd('SDK_STOP');
  }

  removeListenersAndStopSDK() {
    this.removeListeners();
    this.stopSDK();
  }

  forceCaptureFrame() {
    mitekScienceSDK.cmd('CAPTURE_FRAME');
  }

  private handleSdkErrors(): void {
    mitekScienceSDK.on('SDK_ERROR', function (err: Error) {
      appInsightsService.logKnownError(err, 'MiSnap SDK_ERROR');
      RoutingService.goToPage('generic-error', true);
    });
  }

  private startProcessingFeedback(
    captureType: CaptureType,
    documentType: DocumentType
  ) {
    mitekScienceSDK.on(
      'FRAME_PROCESSING_FEEDBACK',
      (result: FrameProcessingFeedback) => {
        const hintCode = result.key;
        this.lastError = hintCode;

        if (hintCode != null) {
          const hintMessage =
            DOCUMENT_SPECIFIC_HINT_OVERRIDES[captureType]?.[hintCode] ??
            AUTOCAPTURE_HINTS[hintCode];

          if (hintMessage) {
            this.showHint(hintMessage, documentType);
          }
        }
      }
    );
  }

  private static instance: MiSnapService;
  public static getInstance() {
    if (!this.instance) this.instance = new MiSnapService();
    return this.instance;
  }
}
