import html from './congrats.html';
import './congrats.scss';

import { removeStickyParent } from '../../shared/utils';
import state from '../../state';
import { Page } from '../page';
import { defaultOptions } from '../page-options';

export class CongratsPage extends Page {
  public get name() {
    return 'Congrats Page';
  }

  public get layoutOptions() {
    return {
      ...defaultOptions,
      hideFooter: false,
      hideHeader: true,
    };
  }

  constructor() {
    super(html);
  }

  afterLoad(): void {
    removeStickyParent();
    state.clearState();
  }
}
