import { TemplateFunction } from 'squirrelly/dist/types/compile';
import * as Sqrl from 'squirrelly';
import { Step } from '../shared/types';
import { defaultOptions, PageOptions } from './page-options';

export abstract class Page {
  template: TemplateFunction;
  public get name(): string {
    return 'Unknown';
  }

  public get layoutOptions(): PageOptions {
    return defaultOptions;
  }

  constructor(templateHtml: string) {
    this.template = Sqrl.compile(templateHtml, { useWith: true });
  }

  loadContent(content: Element | null, params: Record<string, unknown>): void {
    if (!content) return;
    this.setLayout();
    const options = this.getTemplateOptions
      ? this.getTemplateOptions(params)
      : {};
    content.innerHTML = Sqrl.render(this.template, options);

    if (this.afterLoad) this.afterLoad(params);
  }

  setLayout() {
    this.showOrHide('header', this.layoutOptions.hideHeader);
    this.showOrHide('footer', this.layoutOptions.hideFooter);
    this.showOrHide('step-tracker', this.layoutOptions.hideStepTracker);
    this.ammendClass('content', 'full-screen', this.layoutOptions.isFullScreen);
    this.addCloseButtonClickEvent();
  }

  private showOrHide(identifier: string, value: boolean) {
    this.ammendClass(identifier, 'hidden', value);
  }

  private ammendClass(
    identifier: string,
    classModifier: string,
    value: boolean
  ) {
    const element = document.getElementById(identifier);
    if (value) {
      element?.classList.add(`${identifier}--${classModifier}`);
    } else {
      element?.classList.remove(`${identifier}--${classModifier}`);
    }
  }

  setActiveStep(activeStep: Step) {
    document
      .querySelector('.header .step-tracker')
      ?.classList.remove('step-tracker--hidden');

    const activeClass = 'step-tracker__step--active';
    const completedClass = 'step-tracker__step--completed';
    document
      .querySelectorAll('.header .step-tracker .step-tracker__step')
      .forEach((ele, i) => {
        ele.classList.remove(activeClass);
        ele.classList.remove(completedClass);

        if (i === activeStep) {
          ele.classList.add(activeClass);
        } else if (i < activeStep) {
          ele.classList.add(completedClass);
        }
      });
  }

  private addCloseButtonClickEvent() {
    const closeButton = document.getElementById('close');
    closeButton?.addEventListener('click', () => {
      document
        .getElementById('header-notification')
        ?.classList.add('header-notification--hidden');
    });
  }

  afterLoad?(params?: Record<string, unknown>): void;

  onLeavePage?(): void;

  getTemplateOptions?(params: Record<string, unknown>): Record<string, unknown>;
}
