import './not-found.scss';
import html from './not-found.html';

import { Page } from '../../page';
import { RoutingService } from '../../../services';
import { defaultOptions } from '../../page-options';

export class NotFoundPage extends Page {
  public get name() {
    return 'Not Found';
  }

  public get layoutOptions() {
    return {
      ...defaultOptions,
      hideHeader: true,
    };
  }

  constructor() {
    super(html);
  }

  afterLoad(): void {
    const homeButton = document.getElementById('home-button');
    if (homeButton) {
      homeButton.addEventListener('click', () => {
        RoutingService.goToPage('', true);
      });
    }
  }
}
