import {
  AuthenticationResult,
  PublicClientApplication,
} from '@azure/msal-browser';

import { environment } from '../../environments/environment';
import appInsightsService from '../../services/application-insights.service';
import { parseQueryParameters } from '../../shared/utils';
import { getLocationSearch } from '../../shared/window';
import state from '../../state';
import { RoutingService } from '../routing/routing.service';
import { INVALID_LINK_PAGE, LOGIN_QUERY_PARAMS_STORAGE_KEY } from './constants';
import { MSAL_AUTHORITIES, MSAL_CONFIG } from './msal.config';
import { DEVICE_CODE_STORAGE_KEY } from '../../shared/constants';

type IdVerifyClaims = {
  extension_SessionID: string;
  extension_DeviceCode: string;
};

export class MsalAuthService {
  msal = new PublicClientApplication(MSAL_CONFIG);
  defaultOptions = {
    authority: MSAL_AUTHORITIES.default,
    scopes: environment.consentScopes,
  };
  public sessionId: string | null;
  public accessToken: string | null;

  isOnAuthPage(): boolean {
    return window.location.hash.length === 0 && getLocationSearch().length > 0;
  }

  async login(queryParams: Record<string, string>) {
    const redirectPath = this.isOnAuthPage()
      ? '/#/'
      : '/' + window.location.hash;
    sessionStorage.setItem(
      LOGIN_QUERY_PARAMS_STORAGE_KEY,
      JSON.stringify(queryParams)
    );
    return this.msal
      .loginRedirect({
        ...this.defaultOptions,
        extraQueryParameters: queryParams,
        redirectStartPage: redirectPath,
      })
      .catch((err) => {
        this.logAuthfailureAndRedirect(
          err,
          'Failed MSAL:loginRedirect to ' + redirectPath
        );
        sessionStorage.removeItem(LOGIN_QUERY_PARAMS_STORAGE_KEY);
      });
  }

  refreshToken() {
    return this.msal
      .handleRedirectPromise()
      .then((result) => {
        // On Login
        if (result !== null) {
          this.msal.setActiveAccount(result.account);
          // Reset current step and visited steps since this is a new session
          state.clearState();
          this.saveTokenData(result);
          return true;

          // On Page Refresh
        } else if (!this.isOnAuthPage()) {
          return this.acquireToken().then((t) => !!t);

          // On Special auth page
        } else {
          const msalQueryParameters = this.extractMsalQueryParams(
            parseQueryParameters()
          );
          if (msalQueryParameters) {
            this.login(msalQueryParameters);
            return false;
          }
          const error = new Error(
            'MSAL Auth page did not have the expected query params'
          );
          this.logAuthfailureAndRedirect(error, 'Failed to login user');
          return false;
        }
      })
      .catch((err) => {
        this.logAuthfailureAndRedirect(err, 'Failed MSAL:refreshToken');
      });
  }

  async acquireToken() {
    return this.msal
      .acquireTokenSilent({
        ...this.defaultOptions,
        account: this.msal.getActiveAccount() ?? undefined,
      })
      .then((result) => {
        this.saveTokenData(result);
        return this.accessToken;
      })
      .catch((err) => {
        const queryParams = sessionStorage.getItem(
          LOGIN_QUERY_PARAMS_STORAGE_KEY
        );
        if (queryParams) {
          this.login(JSON.parse(queryParams));
        } else {
          this.logAuthfailureAndRedirect(err, 'Failed MSAL:acquireToken');
        }
      });
  }

  saveTokenData(result: AuthenticationResult) {
    const claims = result.idTokenClaims as IdVerifyClaims;
    this.accessToken = result.accessToken;

    this.sessionId = claims.extension_SessionID;
    appInsightsService.setSessionId(this.sessionId);

    const deviceCode = claims.extension_DeviceCode;
    if (deviceCode) {
      sessionStorage.setItem(DEVICE_CODE_STORAGE_KEY, deviceCode);
    }
  }

  extractMsalQueryParams(
    queryParams: Record<string, string>
  ): Record<string, string> | null {
    if (queryParams['id']) {
      return { id: queryParams['id'] };
    }

    if (queryParams['sessionId']) {
      return {
        sessionId: queryParams['sessionId'],
        deviceCode: queryParams['deviceCode'] ?? '',
      };
    }
    return null;
  }

  logAuthfailureAndRedirect(err: Error, logMessage: string): void {
    const additionalDetails = {
      msalActiveAccount: this.msal.getActiveAccount()?.homeAccountId,
      queryParams: sessionStorage.getItem(LOGIN_QUERY_PARAMS_STORAGE_KEY),
      visitedSteps: JSON.stringify(state.visitedSteps),
      windowSearch: location.search,
      windowHash: window.location.hash,
    };
    appInsightsService.logKnownError(err, logMessage, additionalDetails);
    RoutingService.goToPage(INVALID_LINK_PAGE, true);
  }
}

const msalService = new MsalAuthService();
export default msalService;
