import html from './completed.html';
import './completed.scss';

import { Page } from '../page';
import { defaultOptions } from '../page-options';
import { getRedirectUriQueryParameter } from '../../shared/utils';

export class CompletedPage extends Page {
  public get name() {
    return 'Completed Page';
  }

  public get layoutOptions() {
    return {
      ...defaultOptions,
      hideHeader: true,
    };
  }

  get hasRedirectUri(): boolean {
    return !!getRedirectUriQueryParameter();
  }

  constructor() {
    super(html);
  }

  getTemplateOptions(): Record<string, unknown> {
    return {
      hasRedirectUri: this.hasRedirectUri,
    }
  }

  afterLoad(): void {
    const redirectButton = document.getElementById('redirect-button');
    redirectButton?.addEventListener('click', async () => {
      window.location.href = getRedirectUriQueryParameter();
    });
  }
}
