import hasher = require('hasher');

import sessionProgressionGuard from '../../guards';
import state from '../../state';

export class RoutingService {
  /**
   * Navigate to another step in the workflow.
   * @param path the step in the workflow to direct the user to
   * @param resetVisitedState optional parameter to also reset state to a previous step if required.
   */
  public static goToPage(path: string, resetVisitedState = false) {
    if (resetVisitedState) {
      sessionProgressionGuard.redirectResetState(path);
    }

    state.setVisitedSteps(path);
    hasher.setHash(path);
  }
}
