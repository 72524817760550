import axios = require('axios');
import { NotAuthorizedApiResponseInterceptor } from './response-interceptors';

export interface IRejectedResponseInterceptor {
    onRejected(responseUrl: string, httpStatus: number): void;
}

const rejectedResposeInterceptors = [
    new NotAuthorizedApiResponseInterceptor(),
];

function addRejectedResponseInterceptors() {
    // Add a rejected response interceptors
    rejectedResposeInterceptors.forEach(interceptor => {
        axios.default.interceptors.response.use(
            response => response, 
            error => {
                interceptor.onRejected(error.request.responseURL, error.response.status);
                return Promise.reject(error);
            }
        );
    });
}

export function registerInterceptors() {
    //we can add different types of iterceptors
    addRejectedResponseInterceptors();
}