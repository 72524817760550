import sessionProgressionGuard from './guards';
import {
  CaptureFlowPage,
  CompletedPage,
  CongratsPage,
  DocumentCapture,
  GenericErrorPage,
  InvalidLinkPage,
  LandingPage,
  NotFoundPage,
  Page,
  SubmissionFailedPage,
} from './pages';
import './scss/styles.scss';
import applicationInsightsService from './services';
import { CaptureFlowStep, CrossroadRules } from './shared/types';
import { scrollToTop } from './shared/utils';
import state from './state';

import crossroads = require('crossroads');
import hasher = require('hasher');

export function registerRoutes() {
  const landingPage = new LandingPage();
  createRoute(':?state:', landingPage);

  const captureFlow = new CaptureFlowPage();
  createRoute('capture/{step}', captureFlow, {
    step: [
      CaptureFlowStep.Passport,
      CaptureFlowStep.PassportVerify,
      CaptureFlowStep.FrontVerify,
      CaptureFlowStep.Front,
      CaptureFlowStep.FrontVerify,
      CaptureFlowStep.Back,
      CaptureFlowStep.BackVerify,
    ],
  });

  const documentCapture = new DocumentCapture();
  createRoute('photo/{type}', documentCapture, {
    type: ['passport', 'front', 'back'],
  }).switched.add(() => documentCapture.stopImageCapture());

  const congrats = new CongratsPage();
  createRoute('congrats', congrats);

  const completed = new CompletedPage();
  createRoute('completed', completed);

  const submissionFailed = new SubmissionFailedPage();
  createRoute('submission-failed', submissionFailed);

  const genericError = new GenericErrorPage();
  createBasicRoute('generic-error', genericError);

  const invalidLink = new InvalidLinkPage();
  createBasicRoute('invalid-link', invalidLink);

  const notFound = new NotFoundPage();
  createBasicRoute('not-found', notFound);

  // Catch all redirect to invalid link page when no valid route is matched
  crossroads.bypassed.add((requestUrl) => {
    applicationInsightsService.logKnownError(
      new Error('Route not handled: ' + requestUrl),
      'Failed to match a route'
    );
    hasher.replaceHash('not-found');
  });

  hasher.initialized.add(parseHash);
  hasher.changed.add(parseHash);
}

let lastPage: Page | null = null;
function createRoute(path: string, page: Page, rules?: CrossroadRules) {
  const route = crossroads.addRoute(path, (params: Record<string, string>) => {
    if (sessionProgressionGuard.isInvalidState(params.request_)) {
      sessionProgressionGuard.redirect();
    } else {
      if (lastPage && lastPage.onLeavePage) {
        lastPage.onLeavePage();
      }
      lastPage = page;
      loadContent(route, params, page);
      state.setCurrentStep(params.request_);
    }
  });

  if (rules) {
    route.rules = rules;
  }

  return route;
}

function createBasicRoute(path: string, page: Page) {
  const route = crossroads.addRoute(path, (params: Record<string, string>) => {
    loadContent(route, params, page);
  });

  return route;
}

function loadContent(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  route: any,
  params: Record<string, string>,
  page: Page
): void {
  const content = document.getElementById('content');
  page.loadContent(content, params);
  scrollToTop();

  const realPath = '/' + route.interpolate(params);
  applicationInsightsService.logPageView(page.name, realPath);
}

function parseHash(newHash: string): void {
  crossroads.parse(newHash);
}
