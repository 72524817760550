import { CaptureType, DocumentType } from '../../shared/types';
import state from '../../state';
import { FrameCaptureResponse, FrameCaptureResult } from '../misnap/models';
import { EvidenceImage } from '../session/models';
import { ImageResults } from '../misnap/models/image-results.model';

export const SESSION_STORAGE_KEY = 'misnap-results';

export class MiSnapResultsService {
  private static instance: MiSnapResultsService;
  public static getInstance() {
    if (!this.instance) this.instance = new MiSnapResultsService();
    return this.instance;
  }

  saveCapturedImage(
    captureType: CaptureType,
    frameCaptureResult: FrameCaptureResult
  ): void {
    this.updateResults(captureType, frameCaptureResult.response);
  }

  discardCapturedImage(captureType: CaptureType) {
    this.updateResults(captureType, undefined);
  }

  getCapturedImage(captureType: CaptureType): FrameCaptureResponse | undefined {
    return this.loadByCaptureType(captureType);
  }

  getEvidence(): EvidenceImage[] {
    return state.documentType === DocumentType.Passport
      ? this.getPassportEvidence()
      : this.getLicenseOrIdEvidence();
  }

  private getPassportEvidence(): EvidenceImage[] {
    const result = this.getCapturedImageAsEvidence(CaptureType.Passport);
    return [result];
  }

  private getLicenseOrIdEvidence(): EvidenceImage[] {
    const frontResult = this.getCapturedImageAsEvidence(CaptureType.IdFront);
    const backResult = this.getCapturedImageAsEvidence(CaptureType.IdBack);
    return [frontResult, backResult];
  }

  private getCapturedImageAsEvidence(captureType: CaptureType): EvidenceImage {
    const result = this.loadByCaptureType(captureType);
    if (!result) {
      throw new Error(
        'The required images have not been captured for the current document flow'
      );
    }
    return new EvidenceImage(result);
  }

  private loadResults(): ImageResults {
    const capturedImages = sessionStorage.getItem(SESSION_STORAGE_KEY);
    if (!capturedImages) {
      return {} as ImageResults;
    }

    return JSON.parse(capturedImages) as ImageResults;
  }

  private loadByCaptureType(
    captureType: CaptureType
  ): FrameCaptureResponse | undefined {
    const capturedImages = this.loadResults();
    const result = capturedImages[captureType];
    return !!result ? new FrameCaptureResponse(result) : undefined;
  }

  private updateResults(
    captureType: CaptureType,
    updatedValue: FrameCaptureResponse | undefined
  ): void {
    const previousValue = this.loadResults();
    previousValue[captureType] = updatedValue;

    const updatedResultsString = JSON.stringify(previousValue);
    sessionStorage.setItem(SESSION_STORAGE_KEY, updatedResultsString);
  }
}
