import { BrowserCacheLocation, Configuration } from '@azure/msal-browser';

import { environment } from '../../environments/environment';

export const MSAL_AUTHORITIES = {
  default: environment.authority,
};

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const MSAL_CONFIG: Configuration = {
  auth: {
    authority: environment.authority,
    clientId: environment.b2cClientId,
    navigateToLoginRequestUrl: true,
    redirectUri: environment.redirectUrl,
    postLogoutRedirectUri: '/',
    knownAuthorities: [
      MSAL_AUTHORITIES.default,
    ],
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: isIE, // set to true for IE 11
  }
}
