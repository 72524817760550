export enum DocumentType {
  DriversLicense = 'license',
  StateId = 'state-id',
  Passport = 'passport',
}

export enum DocumentFace {
  Front = 'front',
  Back = 'back',
}

export enum CaptureFlowStep {
  Passport = 'passport',
  PassportVerify = 'passport-verify',
  Front = 'front',
  FrontVerify = 'front-verify',
  Back = 'back',
  BackVerify = 'back-verify',
}

export enum CaptureType {
  IdFront = 'DL_FRONT',
  IdBack = 'PDF417_BARCODE',
  Passport = 'PASSPORT',
}

export enum Routes {
  LandingPage = '',
  CapturePassport = 'capture/passport',
  CaptureLicenseId = 'capture/front',
  CaptureLicenseIdBack = 'capture/back',
  PhotoPassport = 'photo/passport',
  PhotoLicenseId = 'photo/front',
  PhotoLicenseIdBack = 'photo/back',
  VerifyPassport = 'capture/passport-verify',
  VerifyLicenseId = 'capture/front-verify',
  VerifyLicenseIdBack = 'capture/back-verify',
  SubmissionFailed = 'submission-failed',
  Congrats = 'congrats',
  Completed = 'completed',
}

export type CrossroadRules = Record<string, CrossroadRule>;

export type CrossroadRule = string[] | RegExp | CrossroadRuleFunc;

export type CrossroadRuleFunc = (
  value: string,
  request: string,
  valuesObj: Record<string, string>
) => boolean;

export enum Step {
  ChooseDocument = 0,
  FrontID = 1,
  BackID = 2,
}

export enum HttpStatusCode {
  Ok = 200,
  BadRequest = 400,
  UnAuthorized = 401,
  Forbidden = 403,
  InternalServerError = 500,
  ServiceUnavailable = 503,
}

export class CaptureStepOptions {
  captureType: CaptureType;
  nextPage: string;
  previousPage: string;
}
