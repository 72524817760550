import hasher = require('hasher');
import { IRejectedResponseInterceptor } from '../..';
import { environment } from '../../../../environments/environment';
import { HttpStatusCode } from '../../../../shared/types';

export class NotAuthorizedApiResponseInterceptor
  implements IRejectedResponseInterceptor
{
  onRejected(responseUrl: string, httpStatus: number): void {
    if (
      responseUrl.startsWith(environment.apiBaseUrl) &&
      httpStatus === HttpStatusCode.UnAuthorized
    ) {
      hasher.replaceHash('generic-error');
    } else if (
      responseUrl.startsWith(environment.apiBaseUrl) &&
      httpStatus === HttpStatusCode.Forbidden
    ) {
      hasher.replaceHash('completed');
    }
  }
}
