import { SESSION_STORAGE_KEY } from '../services/misnap/misnap-results.service';
import { ClientTheme } from '../services/session/models';
import { DocumentType, Routes } from '../shared/types';

const APP_STATE_KEY = 'app-state';
export default class State {
  private _documentType: DocumentType;
  public get documentType(): DocumentType {
    return this._documentType;
  }

  public setDocumentType(type: DocumentType) {
    this._documentType = type;
    this.saveState();
  }

  private _currentStep: string;
  public get currentStep(): string {
    return this._currentStep ?? '';
  }

  public setCurrentStep(step: string) {
    this._currentStep = step;
    if (this._currentStep !== Routes.Congrats) {
      this.saveState();
    }
  }

  private _visitedSteps: string[] = [];
  public get visitedSteps(): string[] {
    return this._visitedSteps;
  }

  public setVisitedSteps(step: string) {
    if (this._visitedSteps === undefined) {
      this._visitedSteps = [];
    }

    if (!this._visitedSteps.includes(step)) {
      this._visitedSteps.push(step);
      this.saveState();
    }
  }

  private _firstName: string;
  public get firstName(): string {
    return this._firstName;
  }

  public setFirstName(name: string) {
    this._firstName = name;
    this.saveState();
  }

  public resetVisitedSteps(index: number) {
    this._visitedSteps.splice(index);
    this.saveState();
  }

  private _clientTheme: ClientTheme;
  public get clientTheme(): ClientTheme {
    return this._clientTheme;
  }

  public setClientTheme(clientTheme: ClientTheme) {
    this._clientTheme = clientTheme;
    this.saveState();
  }

  loadState() {
    try {
      const state = JSON.parse(sessionStorage.getItem(APP_STATE_KEY));
      this._documentType = state?.documentType as DocumentType;
      this._currentStep = state?.currentStep;
      this._visitedSteps = state?.visitedSteps ?? [];
      this._firstName = state?.firstName;
      this._clientTheme = state?.clientTheme;
    } catch (err) {
      // noop
    }
  }

  saveState() {
    sessionStorage.setItem(
      APP_STATE_KEY,
      JSON.stringify({
        documentType: this.documentType,
        currentStep: this.currentStep,
        visitedSteps: this.visitedSteps,
        firstName: this.firstName,
        clientTheme: this.clientTheme,
      })
    );
  }

  clearState() {
    sessionStorage.removeItem(APP_STATE_KEY);
    sessionStorage.removeItem(SESSION_STORAGE_KEY);
    this.loadState();
  }
}
