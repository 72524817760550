import { RoutingService, SessionService } from '../../services';
import { DocumentType, Routes, Step } from '../../shared/types';
import state from '../../state';
import { Page } from '../page';
import html from './landing-page.html';
import './landing-page.scss';

export class LandingPage extends Page {
  public get name() {
    return 'Landing Page';
  }

  private readonly visibilityChangeHandle;
  private retryCameraCheckTimeout: NodeJS.Timeout;
  private hasSessionAlreadyStarted = false;

  constructor() {
    super(html);
    this.visibilityChangeHandle = this.onVisibilityChange.bind(this);
  }

  getTemplateOptions(): Record<string, unknown> {
    return {
      firstName: state.firstName === '' ? 'Welcome' : state.firstName,
    };
  }

  afterLoad(params: Record<string, unknown>): void {
    state.setVisitedSteps(params.request_ as string);
    this.setActiveStep(Step.ChooseDocument);

    // Only log session started on the first time this page is visited
    if (!this.hasSessionAlreadyStarted) {
      SessionService.postSessionStarted();
      this.hasSessionAlreadyStarted = true;
    }

    document.addEventListener('visibilitychange', this.visibilityChangeHandle);
    this.checkCameraPermissions();
    this.bindDocumentSelectionEvents();
  }

  onVisibilityChange() {
    if (!document.hidden) {
      this.checkCameraPermissions();
    }
  }

  checkCameraPermissions() {
    const modalClass = 'landing-page__video-check-modal';
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        // Must close the stream we opened so that MiSnap can capture video.
        stream.getTracks().forEach((track) => track.stop());
        document
          .querySelector('.' + modalClass)
          .classList.remove(modalClass + '--show');
      })
      .catch(() => {
        document
          .querySelector('.' + modalClass)
          ?.classList.add(modalClass + '--show');
        // Try again repeatedly for some devices that let you enable camera without leaving the browser
        this.retryCameraCheckTimeout = setTimeout(
          this.checkCameraPermissions.bind(this),
          2000
        );
      });
  }

  bindDocumentSelectionEvents() {
    const driversLicenseButton = document.getElementById(
      'drivers-license-option'
    );
    const stateIdButton = document.getElementById('state-id-option');

    driversLicenseButton?.addEventListener('click', () => {
      this.selectDocumentType(DocumentType.DriversLicense);
    });

    stateIdButton?.addEventListener('click', () => {
      this.selectDocumentType(DocumentType.StateId);
    });
  }

  selectDocumentType(docType: DocumentType) {
    state.setDocumentType(docType);
    RoutingService.goToPage(Routes.CaptureLicenseId);
  }

  onLeavePage() {
    document.removeEventListener(
      'visibilitychange',
      this.visibilityChangeHandle
    );
    clearTimeout(this.retryCameraCheckTimeout);
  }
}
