export interface PageOptions {
  hideFooter: boolean;
  hideHeader: boolean;
  hideStepTracker: boolean;
  isFullScreen: boolean;
}

export const defaultOptions: PageOptions = {
  hideFooter: true,
  hideHeader: false,
  hideStepTracker: false,
  isFullScreen: false,
};
