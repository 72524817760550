import html from './invalid-link.html';

import { Page } from '../../page';
import { defaultOptions } from '../../page-options';

export class InvalidLinkPage extends Page {
  public get name() {
    return 'Invalid Link';
  }

  public get layoutOptions() {
    return {
      ...defaultOptions,
      hideHeader: true,
    };
  }

  constructor() {
    super(html);
  }
}
