/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  MiSnapService,
  MiSnapResultsService,
  RoutingService,
  SessionService,
} from '../../services';
import appInsightsService from '../../services/application-insights.service';
import {
  CaptureFlowStep,
  CaptureStepOptions,
  CaptureType,
  DocumentType,
  HttpStatusCode,
  Routes,
  Step,
} from '../../shared/types';
import {
  getDocumentDisplayName,
  getRedirectUriQueryParameter,
} from '../../shared/utils';
import state from '../../state';
import { Page } from '../page';
import html from './capture-flow.html';
import './capture-flow.scss';
const { Jimp } = window as typeof window & { Jimp: any };

export class CaptureFlowPage extends Page {
  public get name() {
    return 'Capture Flow';
  }

  private step: CaptureFlowStep;
  misnap: MiSnapService = MiSnapService.getInstance();
  misnapResults: MiSnapResultsService = MiSnapResultsService.getInstance();

  constructor() {
    super(html);
  }

  getTemplateOptions(params: Record<string, string>): Record<string, unknown> {
    this.step = params.step as CaptureFlowStep;

    const templateOptions = {
      step: params.step,
      isVerifyPage: this.isVerifyStep,
      imageToVerifyBytes: '',
      documentType: state.documentType,
      documentName: getDocumentDisplayName(state.documentType),
      documentIcon: this.documentIcon,
      manualError: null as string,
      nextButtonText: this.nextButtonText,
      previousButtonText: this.previousButtonText,
      completeButtonText: 'Looks Good, Continue',
      heading: this.heading,
      isFinalStep: this.isFinalStep,
    };

    if (this.isVerifyStep) {
      const captureResult = this.getCaptureResult();
      templateOptions.imageToVerifyBytes = captureResult?.imageBytes;
      templateOptions.manualError = captureResult?.statusMessage;
    }

    return templateOptions;
  }

  afterLoad(): void {
    const activeStep =
      this.flowConfig.captureType === CaptureType.IdFront
        ? Step.FrontID
        : Step.BackID;
    this.setActiveStep(activeStep);

    const nextButton = document.getElementById('next-button');
    nextButton?.addEventListener('click', async () => {
      RoutingService.goToPage(this.flowConfig.nextPage);
    });

    const completeButton = document.getElementById('complete-button');
    completeButton?.addEventListener('click', async () => {
      const isSuccess = await this.submitIdImages();
      if (isSuccess && !!getRedirectUriQueryParameter()) {
        window.location.href = getRedirectUriQueryParameter();
        state.clearState();
      } else if (isSuccess) {
        RoutingService.goToPage(this.flowConfig.nextPage);
      } else {
        RoutingService.goToPage('submission-failed');
      }
    });

    const changeIdTypeButton = document.getElementById('restart-button');
    changeIdTypeButton?.addEventListener('click', () => {
      RoutingService.goToPage(Routes.LandingPage, true);
    });

    const previousButton = document.getElementById('previous-button');
    previousButton?.addEventListener('click', () => {
      if (this.isVerifyStep) {
        this.misnapResults.discardCapturedImage(this.flowConfig.captureType);
      }
      RoutingService.goToPage(this.flowConfig.previousPage, true);
    });

    const imageToVerify: HTMLImageElement = document.getElementById(
      'imageToVerify'
    ) as HTMLImageElement;

    const rotateButton = document.getElementById('rotate-button');
    rotateButton?.addEventListener('click', () => {
      this.rotateImageElement(imageToVerify, 90);
    });
  }

  private async rotateImageElement(
    imageElement: HTMLImageElement,
    degrees: number
  ) {
    const imageBytes = imageElement.src;

    try {
      const imageBuffer = Buffer.from(imageBytes.split(',')[1], 'base64');
      const jimp = await Jimp.read(imageBuffer);
      const newAngleOfRotation =
        degrees -
        this.getOffsetAfterAutoRotation(this.getExifOrientation(jimp));
      const newSrc = await jimp
        .rotate(newAngleOfRotation)
        .getBase64Async(Jimp.MIME_JPEG);
      imageElement.src = newSrc;
    } catch (error: any) {
      console.log(`Could not rotate image: ${error}`);
    }
  }

  getExifOrientation(img: any) {
    return (img._exif && img._exif.tags && img._exif.tags.Orientation) || 1;
  }

  //Handle Jimp's auto-rotation. Angles are weird because exifrotate() is clockwise and rotate() is counter-clockwise.
  getOffsetAfterAutoRotation(exifOrientation: number) {
    const exifOffsetDegrees = [0, 0, 0, 180, 180, -90, -90, 90, 90];
    return exifOffsetDegrees[exifOrientation];
  }

  async submitIdImages() {
    this.showSpinner();
    return await SessionService.postPhotoSubmit()
      .then(() => true)
      .catch((err) => {
        if (err.response && err.response.status !== HttpStatusCode.BadRequest) {
          appInsightsService.handleGenericNetworkError()(err);
        }
        return false;
      })
      .finally(() => this.hideSpinner());
  }

  showSpinner() {
    const loadingModal = document.getElementById('capture-flow-loading');
    if (loadingModal) {
      loadingModal.classList.remove('capture-flow-page__loading--hidden');
    }
  }

  hideSpinner() {
    const loadingModal = document.getElementById('capture-flow-loading');
    if (loadingModal) {
      loadingModal.classList.add('capture-flow-page__loading--hidden');
    }
  }

  getCaptureResult() {
    return this.misnapResults.getCapturedImage(this.flowConfig.captureType);
  }

  get flowConfig() {
    return flows[state.documentType][this.step];
  }

  get isFinalStep() {
    return (
      this.step === CaptureFlowStep.PassportVerify ||
      this.step === CaptureFlowStep.BackVerify
    );
  }

  get isVerifyStep() {
    return (
      this.step === CaptureFlowStep.PassportVerify ||
      this.step === CaptureFlowStep.FrontVerify ||
      this.step === CaptureFlowStep.BackVerify
    );
  }

  get nextButtonText() {
    return this.isVerifyStep ? 'Looks Good, Continue' : 'Take Photo';
  }

  get previousButtonText() {
    return this.isVerifyStep ? 'Retake Photo' : 'Back';
  }

  get heading() {
    return pageTitles[this.flowConfig.captureType];
  }

  get documentIcon() {
    return documentIcons[this.flowConfig.captureType];
  }
}

const documentIcons: Record<CaptureType, string> = {
  [CaptureType.Passport]: 'passport',
  [CaptureType.IdFront]: 'license',
  [CaptureType.IdBack]: 'license-back',
};

const pageTitles: Record<CaptureType, string> = {
  [CaptureType.Passport]: 'Take a photo of your Passport',
  [CaptureType.IdFront]: 'Take a photo of the front of your ID',
  [CaptureType.IdBack]: 'Take a photo of the back of your ID',
};

const flows: Partial<
  Record<DocumentType, Partial<Record<CaptureFlowStep, CaptureStepOptions>>>
> = {
  [DocumentType.Passport]: {
    [CaptureFlowStep.Passport]: {
      captureType: CaptureType.Passport,
      nextPage: 'photo/passport',
      previousPage: Routes.LandingPage,
    },
    [CaptureFlowStep.PassportVerify]: {
      captureType: CaptureType.Passport,
      nextPage: 'congrats',
      previousPage: 'photo/passport',
    },
  },
  [DocumentType.DriversLicense]: {
    [CaptureFlowStep.Front]: {
      captureType: CaptureType.IdFront,
      nextPage: 'photo/front',
      previousPage: Routes.LandingPage,
    },
    [CaptureFlowStep.FrontVerify]: {
      captureType: CaptureType.IdFront,
      nextPage: 'capture/back',
      previousPage: 'photo/front',
    },
    [CaptureFlowStep.Back]: {
      captureType: CaptureType.IdBack,
      nextPage: 'photo/back',
      previousPage: 'capture/front-verify',
    },
    [CaptureFlowStep.BackVerify]: {
      captureType: CaptureType.IdBack,
      nextPage: 'congrats',
      previousPage: 'photo/back',
    },
  },
};
// State ID is identical to License
flows[DocumentType.StateId] = { ...flows[DocumentType.DriversLicense] };
