import { CaptureType } from '../../../shared/types';
import { getCaptureTypeFromValue } from '../../../shared/utils';
import { FrameCaptureResponse } from '../../misnap/models';
import { MISNAP_DEFAULT_BARCODE } from '../../misnap';

export class EvidenceImage {
  photoType: string;
  imageBytes: string;
  pdf417Barcode?: string;

  constructor(response: FrameCaptureResponse) {
    this.imageBytes = response.imageBytes;

    const captureType = response.docType as CaptureType;
    this.photoType = getCaptureTypeFromValue(captureType);

    // response.code will have a default value of -1 if the user force-captures the frame for Back of ID.
    // We don't want to send this value to MiTek's API since it will fail the submission.
    this.pdf417Barcode =
      response.code === MISNAP_DEFAULT_BARCODE ? null : response.code;
  }
}
